import { isMobile } from '@/assets/js/utils';

const baseSize = 37.5;
const clientWidth = isMobile() ? 750 : 1920
// 设置 rem 函数
function setRem() {
  // 当前页面宽度相对于 1920宽的缩放比例，可根据自己需要修改。
  const scale = document.documentElement.clientWidth / clientWidth;
  // 设置页面根节点字体大小（“Math.min(scale, 2)” 指最高放大比例为2，可根据实际业务需求调整）
  document.documentElement.style.fontSize =
    baseSize * Math.min(scale, 2) + 'px';
}

setRem();
window.onresize = function () {
  setRem();
};
