import NProgress from 'nprogress';
import 'nprogress/nprogress.css';

export function setupGuard(router) {
  NProgress.configure({
    easing: 'ease',
    speed: 500,
    trickleSpeed: 200,
    showSpinner: false,
  });

  router.beforeEach(async (to, from, next) => {
    NProgress.start();
    next();
  });

  router.afterEach(() => {
    NProgress.done();
  });
}
