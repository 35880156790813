<script setup>
import { onMounted, ref, nextTick } from 'vue';
import { useRouter } from 'vue-router';
import * as spine from '@esotericsoftware/spine-player';
import { Mousewheel, Pagination, Autoplay, Keyboard } from 'swiper';
import TopNav from '@/components/top-nav/index.vue';
import VedioPlayer from '@/components/videoPlayer/index.vue';
import YoutubePlayer from '@/components/youtubePlayer/index.vue';
import Footer from '@/components/footer/index.vue';
import { Swiper, SwiperSlide } from 'swiper/vue';
import { typeTranslate } from '@/assets/js/utils';
import { getArticleLists, getBannerLists } from '@/api/index';

import 'swiper/css';
import 'swiper/css/pagination';
import '@esotericsoftware/spine-player/dist/spine-player.css';

defineOptions({
  name: 'Home',
});

const loadSpin = (player, urlName, animation, viewport) => {
  new spine.SpinePlayer(player, {
    skelUrl: `/spine/${urlName}.skel`,
    atlasUrl: `/spine/${urlName}.atlas`,
    animation: animation,
    backgroundColor: '#00000000',
    showLoading: false,
    showControls: false,
    alpha: true,
    viewport: viewport,
  });
};
const swiperIndex = ref(0);
const homeActiveIndex = ref(0);
const activeNav = ref('home');
const footerShow = ref(false);
const scrollView = (item, index) => {
  footerShow.value = false;
  activeNav.value = item.name;
  homeActiveIndex.value = index;
  homeSwiperRef.value.slideTo(index, 1000, false);
  if (index === 3) {
    loadFeatureSwiper();
  }
};

const modules = [Mousewheel];
const newsModules = [Pagination, Autoplay];
const newsBanner = ref([]);
const featureModules = [Autoplay, Keyboard];
const newslist = ref([]);
const newsBulletRenderer = (index, className) => {
  const _className = `${className} swiper-pagination-bullet-custom`;
  return `<span class="${_className}"></span>`;
};
const worldList = ref([
  {
    name: 'defence',
  },
  {
    name: 'melee',
  },
  {
    name: 'ranged',
  },
  {
    name: 'support',
  },
]);
const worldActive = ref('');

const mouseOverHandler = (item) => {
  worldActive.value = item.name;
};
const featureList = ref([
  // {
  //   name: 'one',
  //   text: "Tucked between mountains, this village boasts a rich history and simple customs. Generations of villagers have lived here in tranquility and harmony. Secluded from the hustle and bustle, time seems to flow more gently, creating a haven reminiscent of a mythical utopia. Ancient trees reach for the skies, while babbling brooks narrate the village's age-old legends, safeguarding the peaceful haven of its inhabitants.",
  // },
  {
    name: 'one',
    text: 'Located in the barren wilds, legend has it that this place was once a battlefield, where blood flowed like rivers, hence its ominous name. As time passed, it remains desolate and eerie, with howling cold winds that deter any soul from nearing. Rumors tell of strange noises heard at night, chilling to the core, making it a famously forbidden zone far and wide.',
    id: '3bN37tMK5zY',
  },
  {
    name: 'two',
    text: "Hidden deep within the mountain's belly, mysterious and winding. The cave walls are lined with bizarre rocks and stalactites hang from above, casting mottled light and shadows. Inside, the cave alternates between utter silence and the gentle sound of dripping water, as if whispering the secrets of the ages, drawing countless adventurers to explore its depths.",
    id: 'EHbAgtKqf-o',
  },
  {
    name: 'three',
    text: 'Standing tall on the vast land, weathered by storms yet still majestic. Ancient totems carved on the stone walls speak of former glory. The ruins hide countless secrets, drawing explorers from all over to discover them. Each stone seems to tell a thousand-year-old legend.',
    id: 'vN5gvbdbHp4',
  },
  {
    name: 'four',
    text: 'Vast and boundless, the land is covered in lush green grass, with herds of cattle and sheep grazing. The gentle breeze stirs the grass, creating rolling waves, while the air is filled with the scent of flowers. This is a natural paradise, an oasis of life. Here, the herders graze their livestock, living in harmony with nature, together weaving the legend of the grasslands',
    id: 'J8Jb-gt3MEE',
  },
  {
    name: 'five',
    text: 'Bustling and lively, neon lights flicker. Skyscrapers stand tall, streets are filled with the hustle and bustle, and crowds move like a tide. This place is a shopping paradise, a haven for gourmet food. Every corner of the streets radiates the vitality and charm of a modern city, drawing countless visitors to experience the prosperity and diversity of this urban landscape.',
    id: 'qFh418HBi6U',
  },
  {
    name: 'six',
    text: "Hidden deep beneath the earth, abundant treasures lie alongside lurking dangers. The palace's corridors twist and turn, light and shadows intertwine, as if leading to another mysterious world. Legend has it that only those who possess both wisdom and courage can unravel the secrets of this underground palace, obtaining endless wealth and glory.",
    id: 'PKSc70p5DIo',
  },
  {
    name: 'serven',
    text: 'In the wild West, brave cowboys ride across the vast prairies, engaging in life-and-death struggles with outlaws. Under the scorching sun, iron steeds gallop, amidst a hail of bullets, legendary tales abound. In this land filled with challenges and opportunities, only the courageous can script their own chapters of glory.',
    id: 'v_P5TyF1M5A',
  },
]);

const videoShow = ref(false);
const videoPlayerRef = ref();
const videoId = ref('');
const homeSwiperRef = ref(null);

const setHomeSwiperRef = (swiper) => {
  homeSwiperRef.value = swiper;
};

const featureSwiperRef = ref(null);
const setFeatureSwiperRef = (swiper) => {
  featureSwiperRef.value = swiper;
};
const updateSwiperIndex = () => {
  if (featureSwiperRef.value) {
    swiperIndex.value = featureSwiperRef.value.realIndex;
    featureSwiperRef.value.autoplay.start();
  }
};
const slideChangeTransitionStart = () => {
  homeActiveIndex.value = homeSwiperRef.value.activeIndex;
  if (homeActiveIndex.value === 0) {
    activeNav.value = 'home';
  } else if (homeActiveIndex.value === 1) {
    activeNav.value = 'news';
    // activeNav.value = 'register';
  } else if (homeActiveIndex.value === 2) {
    activeNav.value = 'world';
  } else if (homeActiveIndex.value === 3) {
    activeNav.value = 'features';
    loadFeatureSwiper();
  }
};
const loadFeatureSwiper = () => {
  setFeatureSwiperRef();
  videoShow.value = false;
  swiperIndex.value = 0;
};
const prevSwiperSlide = () => {
  videoShow.value = false;
  if (featureSwiperRef.value) {
    featureSwiperRef.value.autoplay.start();
    featureSwiperRef.value.slidePrev();
  }
};
const nextSwiperSlide = () => {
  videoShow.value = false;
  if (featureSwiperRef.value) {
    featureSwiperRef.value.autoplay.start();
    featureSwiperRef.value.slideNext();
  }
};

const keyboardPress = () => {
  videoShow.value = false;
  if (featureSwiperRef.value) {
    featureSwiperRef.value.autoplay.start();
  }
};

const handelItem = (item, index) => {
  videoShow.value = false;
  if (featureSwiperRef.value) {
    featureSwiperRef.value.autoplay.start();
    featureSwiperRef.value.slideTo(index + 5, 2000, false);
  }
};

const scrollFunc = (e) => {
  e = e || window.event;
  if (e.wheelDelta) {
    //IE/Opera/Chrome
    if (e.wheelDelta > 0) {
      //当鼠标滚轮向上滚动时
      footerShow.value = false;
    }
    if (e.wheelDelta < 0) {
      //当鼠标滚轮向下滚动时
      footerShow.value = true;
    }
  } else if (e.detail) {
    //Firefox滑轮事件
    if (e.detail < 0) {
      //当鼠标滚轮向上滚动时
      footerShow.value = false;
    }
    if (e.detail > 0) {
      //当鼠标滚轮向下滚动时
      footerShow.value = true;
    }
  }
};

const router = useRouter();
const handelMore = () => {
  router.push('/newslists');
};

const handelNewInfo = (item) => {
  router.push({
    path: '/newsdetail',
    query: {
      id: item.id,
    },
  });
};
const getArticleListData = () => {
  getArticleLists({
    game_id: 2,
    type: 0,
    page: 1,
    limit: 6,
  }).then((res) => {
    newslist.value = res.data.list.map((v) => ({
      published_at: v.published_at.slice(0, 10),
      name: v.name,
      id: v.id,
      flag: v.flag,
      type: v.type,
    }));
  });
};

const getBanner = () => {
  getBannerLists({
    game_id: 2,
    page: 1,
    limit: 5,
  }).then((res) => {
    newsBanner.value = res.data.list;
  });
};

const playBgVideo = () => {
  videoShow.value = true;
  nextTick(() => {
    featureSwiperRef.value.autoplay.stop();
    videoId.value = featureList.value[swiperIndex.value].id;
  });
};

onMounted(() => {
  nextTick(() => {
    setTimeout(() => {
      videoPlayerRef.value.playVideo('swiper-one-bg');
    }, 200);
  });
  const windowsHeight = window.innerHeight;
  const windowsWidth = window.innerWidth;
  loadSpin(spinePlayerHb, 'dlbj_bj_guanwsy3', 'a1', {
    width: windowsWidth,
    height: windowsHeight,
    padLeft: '0%',
    padRight: '0%',
    padTop: '0%',
    padBottom: '0%',
  });
  loadSpin(spinePlayerGd, 'dlbj_bj_guanwsy1', 'a2', {
    width: windowsWidth,
    height: windowsHeight,
    padLeft: '0%',
    padRight: '0%',
    padTop: '0%',
    padBottom: '0%',
  });
  loadSpin(spinePlayerGds, 'dlbj_bj_guanwsy1', 'a2', {
    width: windowsWidth,
    height: windowsHeight,
    padLeft: '0%',
    padRight: '0%',
    padTop: '0%',
    padBottom: '0%',
  });
  const slideFeature = document.getElementById('slide-feature');
  if (slideFeature.addEventListener) {
    slideFeature.addEventListener('DOMMouseScroll', scrollFunc, false);
  }
  slideFeature.onmousewheel = slideFeature.onmousewheel = scrollFunc;
  getArticleListData();
  getBanner();
});
</script>
<template>
  <div class="home">
    <TopNav
      :active="activeNav"
      @change="scrollView"></TopNav>
    <Swiper
      style="height: 100%"
      class="home-swiper"
      :modules="modules"
      direction="vertical"
      :mousewheel="true"
      :allow-touch-move="false"
      :threshold-time="5000"
      @swiper="setHomeSwiperRef"
      @slide-change-transition-start="slideChangeTransitionStart">
      <!-- :slides-per-view="'auto'" -->
      <SwiperSlide class="slide-home">
        <div
          class="position-absolute spine-player-cloud"
          id="spinePlayerBg"></div>
        <div class="position-absolute spine-player-bgimg"></div>
        <VedioPlayer ref="videoPlayerRef"></VedioPlayer>
        <div class="scroll"></div>
        <div class="swiper-home-con animate__animated animate__fadeInUp">
          <img
            src="https://cdn.k2games.cc/idlepixel/assets/images/swiper-one/wenan.png"
            alt="" />
          <div class="flex flex-center">
            <img
              src="https://cdn.k2games.cc/idlepixel/assets/images/swiper-one/icon.png"
              alt="" />
            <div class="icon-list">
              <div>
                <a
                  href="https://play.google.com/store/apps/details?id=cc.k2games.ipss">
                  <img
                    src="https://cdn.k2games.cc/idlepixel/assets/images/swiper-one/google.png"
                    alt="" />
                </a>
                <a>
                  <img
                    src="https://cdn.k2games.cc/idlepixel/assets/images/swiper-one/ios.png"
                    alt="" />
                </a>
              </div>
              <div>
                <a>
                  <img
                    src="https://cdn.k2games.cc/idlepixel/assets/images/swiper-one/windows.png"
                    alt="" />
                </a>
                <a href="https://www.taptap.io/user/632061637">
                  <img
                    src="https://cdn.k2games.cc/idlepixel/assets/images/swiper-one/tap.png"
                    alt="" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </SwiperSlide>
      <SwiperSlide class="slide-news flex flex-center">
        <div class="mask"></div>
        <div
          class="spine-player spine-player-hb"
          id="spinePlayerGd"></div>
        <div
          class="swiper-news-con flex flex-center"
          v-if="homeActiveIndex === 1">
          <div class="swiper-left animate__animated animate__fadeInLeft">
            <div class="swiper-bg swiper-bg-one"></div>
            <Swiper
              :modules="newsModules"
              :pagination="{
                clickable: true,
                renderBullet: newsBulletRenderer,
              }"
              :autoplay="{
                delay: 2000,
                disableOnInteraction: false,
              }">
              <SwiperSlide
                v-for="(item, index) in newsBanner"
                :key="index">
                <img
                  :src="item.pic"
              /></SwiperSlide>
            </Swiper>
          </div>
          <div class="news-lists-box animate__animated animate__fadeInUp">
            <div class="title flex flex-between">
              <div class="news-title"></div>
              <div
                class="more"
                @click="handelMore"></div>
            </div>
            <div
              class="list-item flex"
              v-for="(item, index) in newslist"
              :key="index"
              @click="handelNewInfo(item)">
              <label
                :class="['type', `type_${typeTranslate(item.type)}`]"></label>
              <span class="text">{{ item.name }}</span>
              <span>{{ item.published_at }}</span>
            </div>
          </div>
        </div>
      </SwiperSlide>
      <!-- <SwiperSlide class="slide-register flex flex-center">
        <div class="mask position-absolute"></div>
        <div class="slide-register-left"></div>
        <div class="slide-register-center flex flex-column">
          <div class="register-center-title"></div>
          <p>1.Click the button below to pre-register and get gifts</p>
          <div class="flex imgs-buttom">
            <div class="google-img"></div>
            <div class="apple-img"></div>
          </div>
          <p>2.Follow our official channels for more details</p>
          <div class="flex imgs-channels">
            <div class="discord-img"></div>
            <div class="facebook-img"></div>
          </div>
          <div class="click-to"></div>
        </div>
        <div class="slide-register-right flex flex-center flex-column">
          <div class="register-right-title"></div>
          <div class="register-right-line"></div>
          <ul class="rewards-lists flex">
            <li>
              <div class="gold"></div>
            </li>
            <li>
              <div class="dice"></div>
            </li>
            <li>
              <div class="ruby"></div>
            </li>
          </ul>
          <div class="register-right-line"></div>
          <div class="register-right-text"></div>
          <ul class="rewards-lists-more flex">
            <li>
              <div class="gold"></div>
            </li>
            <li>
              <div class="dice"></div>
            </li>
            <li>
              <div class="ruby"></div>
            </li>
            <li class="bg-jin">
              <div class="ruby"></div>
            </li>
          </ul>
        </div>
      </SwiperSlide> -->
      <SwiperSlide class="slide-world">
        <div class="mask"></div>
        <div
          class="spine-player spine-player-hb"
          id="spinePlayerHb"></div>
        <div
          class="swiper-world-con position-absolute flex flex-center flex-column"
          v-if="homeActiveIndex === 2">
          <div class="title animate__animated animate__fadeInUp"></div>
          <div
            class="text animate__animated animate__fadeInUp animate__delay-0.15s"></div>
          <ul
            class="world-list flex flex-center animate__animated animate__fadeInUp animate__delay-0.15s">
            <li
              v-for="(item, index) in worldList"
              :class="[item.name, worldActive === item.name ? 'active' : '']"
              :key="index"
              @mouseover="mouseOverHandler(item)">
              <div :class="['gif-bg', `gif-bg-${item.name}`]"></div>
            </li>
          </ul>
        </div>
      </SwiperSlide>
      <SwiperSlide
        class="slide-feature flex flex-center"
        id="slide-feature">
        <div class="feature-bg">
          <div :class="`feature-bg-${featureList[swiperIndex].name}`"></div>
        </div>
        <div
          class="spine-player spine-player-hb"
          id="spinePlayerGds"></div>

        <div class="mask"></div>
        <div
          class="swiper-feature-con flex"
          v-if="homeActiveIndex === 3">
          <div class="swiper-left animate__animated animate__fadeInLeft">
            <div class="swiper-bg swiper-bg-two"></div>

            <YoutubePlayer
              v-if="videoShow"
              :videoId="videoId"></YoutubePlayer>
            <div
              class="feature-bg"
              v-else>
              <div :class="`feature-bg-${featureList[swiperIndex].name}`"></div>
              <div
                class="play-btn"
                @click="playBgVideo"></div>
            </div>
          </div>
          <div
            class="news-lists-box animate__animated animate__fadeInUp animate__delay-0.1s">
            <div
              v-for="(items, indexs) in featureList"
              :key="indexs">
              <template v-if="indexs === swiperIndex">
                <div class="title">
                  <div :class="['item-title', `${items.name}-title`]"></div>
                </div>
                <div class="item-text">{{ items.text }}</div>
              </template>
            </div>
          </div>
        </div>
        <div
          class="swiper-list animate__animated animate__fadeInUp animate__delay-0.3s"
          v-if="homeActiveIndex === 3">
          <swiper
            class="feature-swiper"
            :modules="featureModules"
            :autoplay="{
              delay: 900000,
              disableOnInteraction: false,
            }"
            :slides-per-view="5"
            :space-between="14"
            :loop="true"
            :keyboard="true"
            :centered-slides="true"
            @swiper="setFeatureSwiperRef"
            @slide-change="updateSwiperIndex"
            @key-press="keyboardPress">
            <swiper-slide
              v-for="(item, index) in featureList"
              :key="index"
              @click="handelItem(item, index)">
              <div :class="['slide-item', `feature-bg-${item.name}`]"></div>
            </swiper-slide>
          </swiper>

          <div
            class="button-prev button"
            @click="prevSwiperSlide"></div>

          <div
            class="button-next button"
            @click="nextSwiperSlide"></div>
        </div>
      </SwiperSlide>
      <!-- <SwiperSlide
        class="slide-footer"
        id="footer">
        <Footer></Footer>
      </SwiperSlide> -->
    </Swiper>
    <Footer v-if="footerShow"></Footer>
  </div>
</template>

<style lang="scss" scoped>
$world-list-name: 'defence', 'melee', 'ranged', 'support';

.home {
  position: relative;
  width: 100%;
  height: 100%;
  .home-swiper {
    width: 100%;
    height: 100%;
  }
  .slide-home {
    background: url(#{$cdnUrl}/assets/images/swiper-one/z02.jpg) no-repeat
      center bottom;
    background-size: cover;
  }
  .slide-news {
    background: url(#{$cdnUrl}/assets/images/swiper-two/bg_news_bg.jpg)
      no-repeat center center;
    background-size: cover;
  }
  .slide-register {
    background: url(#{$cdnUrl}/assets/images/pre-register/bg_yy_bg.jpg)
      no-repeat no-repeat center center;
    background-size: cover;
  }
  .slide-world {
    background: url(#{$cdnUrl}/assets/images/swiper-three/bg_world_bg.jpg)
      no-repeat no-repeat center center;
    background-size: cover;
  }
  .slide-feature {
    background: url(#{$cdnUrl}/assets/images/swiper-four/img_ts_bg_ts_bg.png)
      repeat;
    background-size: cover;
  }
  .title {
    width: 616px;
    background: url(#{$cdnUrl}/assets/images/swiper-two/img_news_line.png)
      no-repeat bottom center;
    padding: 0 10px 18px 10px;
  }
  .swiper-left {
    width: 808px;
    height: 515px;
    position: relative;
    overflow: hidden;
    .swiper-bg {
      position: absolute;
      width: 808px;
      height: 454px;
      z-index: 99;
    }
    .swiper-bg-one {
      background: url(#{$cdnUrl}/assets/images/swiper-two/img_news_wk.png)
        no-repeat;
      background-size: 100%;
    }
    .swiper-bg-two {
      background: url(#{$cdnUrl}/assets/images/swiper-four/img_ts_wk.png)
        no-repeat;
      background-size: 100%;
      pointer-events: none;
    }
  }
  .spine-player {
    position: absolute;
  }
  .slide-home .spine-player-hb {
    z-index: 2;
  }
  .spine-player-bgimg {
    background: url(#{$cdnUrl}/assets/images/swiper-one/z01.png) no-repeat
      center bottom;
    background-size: cover;
  }
  .spine-player-cloud {
    width: 360%;
    height: 360%;
    transform: translate(-50%, -50%);
  }
  .spine-player-bg {
    z-index: 4;
    bottom: 0;
    width: 100%;
    height: 520px * 1.47;
  }
  .spine-player-qs {
    left: 50%;
    margin-left: -(549px * 1.47 / 2.3);
    z-index: 3;
    width: 549px * 1.47;
    height: 923px * 1.47;
    bottom: -(923px * 1.47/4.6);
  }
  .spine-player-js {
    width: 379px * 1.29;
    height: 394px * 1.29;
    left: 130px;
    bottom: 220px;
    z-index: 0;
  }
  .spine-player-lhn {
    width: 393px * 1.29;
    height: 489px * 1.29;
    left: 310px;
    bottom: 100px;
    z-index: 1;
  }
  .spine-player-xm {
    width: 419px * 1.29;
    height: 405px * 1.29;
    right: 300px;
    bottom: 130px;
    z-index: 0;
  }
  .spine-player-lt {
    width: 367px * 1.29;
    height: 388px * 1.29;
    right: 90px;
    bottom: 230px;
    z-index: 1;
  }
  .news-lists-box {
    width: 713px;
    height: 604px;
    background: url(#{$cdnUrl}/assets/images/swiper-two/img_maskan.png)
      no-repeat;
    background-size: 100%;
    padding-left: 54px;
  }
  .slide-home {
    .scroll {
      position: absolute;
      left: 50%;
      bottom: 70px;
      width: 69px;
      height: 41px;
      background: url(#{$cdnUrl}/assets/images/swiper-one/btn_main1_scroll.png)
        no-repeat;
      background-size: 100%;
      animation: cutTips 0.6s ease-in-out infinite alternate;
      z-index: 999;
      cursor: pointer;
      margin-left: -34.5px;
    }
    .swiper-home-con {
      position: absolute;
      right: 43px;
      bottom: 70px;
      z-index: 99;
      .icon-list {
        img {
          margin: 5px 16px;
          vertical-align: middle;
        }
      }
    }
  }
  .slide-register {
    .slide-register-left {
      width: 371px;
      height: 469px;
      background: url(#{$cdnUrl}/assets/images/pre-register/img_yy_hero.png)
        no-repeat;
      background-size: 100%;
    }
    .slide-register-center {
      width: 440px;
      height: 978px;
      background: url(#{$cdnUrl}/assets/images/pre-register/img_yy_tiao.png)
        no-repeat;
      background-size: cover;
      color: #fff;
      justify-content: center;
      p {
        font-size: 16px;
        padding: 0 40px;
      }
      .register-center-title {
        width: 310px;
        height: 51px;
        background: url(#{$cdnUrl}/assets/images/pre-register/img_yy.png)
          no-repeat;
        background-size: 100%;
        margin: 0 auto 28px auto;
      }
      .imgs-buttom {
        margin: 20px auto;
        div {
          width: 160px;
          height: 44px;
          margin: 0 10px;
        }
        .google-img {
          background: url(#{$cdnUrl}/assets/images/pre-register/btn_yy_google.png)
            no-repeat;
          background-size: 100%;
        }
        .apple-img {
          background: url(#{$cdnUrl}/assets/images/pre-register/btn_yy_ios.png)
            no-repeat;
          background-size: 100%;
        }
      }
      .imgs-channels {
        margin: 20px auto;
        div {
          width: 162px;
          height: 48px;
          margin: 0 10px;
        }
        .discord-img {
          background: url(#{$cdnUrl}/assets/images/pre-register/btn_yy_discord.png)
            no-repeat;
          background-size: 100%;
        }
        .facebook-img {
          background: url(#{$cdnUrl}/assets/images/pre-register/btn_yy_facebook.png)
            no-repeat;
          background-size: 100%;
        }
      }
      .click-to {
        width: 320px;
        height: 62px;
        margin: 0 auto;
        background: url(#{$cdnUrl}/assets/images/pre-register/btn_yy_click.png)
          no-repeat;
        background-size: 100%;
        margin-top: 30px;
        cursor: pointer;
      }
    }
    .slide-register-right {
      width: 463px;
      margin-left: 15px;
      .register-right-title {
        width: 303px;
        height: 71px;
        background: url(#{$cdnUrl}/assets/images/pre-register/rewards.png)
          no-repeat;
        background-size: 100%;
        margin-bottom: 20px;
      }
      .register-right-text {
        width: 324px;
        height: 39px;
        background: url(#{$cdnUrl}/assets/images/pre-register/more.png)
          no-repeat;
        background-size: 100%;
        margin: 10px auto 14px auto;
      }
      .register-right-line {
        width: 100%;
        height: 12px;
        background: url(#{$cdnUrl}/assets/images/pre-register/img_yy_line.png)
          no-repeat;
        background-size: 100%;
      }
      .rewards-lists {
        margin: 0 auto;
        li {
          width: 111px;
          height: 110px;
          background: url(#{$cdnUrl}/assets/images/pre-register/btn_yy_iconban.png)
            no-repeat;
          background-size: 100%;
          margin: 16px 8px;
        }
      }
      .rewards-lists-more {
        margin: 0 auto;
        color: #fff;
        li {
          width: 111px;
          height: 229px;
          background: url(#{$cdnUrl}/assets/images/pre-register/btn_yy_goldbanbule.png)
            no-repeat;
          background-size: 100%;
        }
        .bg-jin {
          background: url(#{$cdnUrl}/assets/images/pre-register/btn_yy_goldbanjin.png)
            no-repeat;
          background-size: 100%;
        }
      }
    }
  }
  .slide-news {
    .swiper-news-con {
      justify-content: center;
      padding-top: 132px;
      .swiper-left {
        .swiper {
          position: absolute;
          overflow: hidden;
          width: 99%;
          height: 99%;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
          ::v-deep(.swiper-wrapper) {
            height: 90%;
          }
          .swiper-slide {
            img {
              width: 808px;
              height: 452px;
            }
          }
          ::v-deep(.swiper-pagination-bullet-custom) {
            width: 25px !important;
            height: 24px !important;
            background: url(#{$cdnUrl}/assets/images/swiper-two/btn_news_lb0.png)
              no-repeat;
            background-size: 100%;
            &.swiper-pagination-bullet-active {
              background: url(#{$cdnUrl}/assets/images/swiper-two/btn_news_lb.png)
                no-repeat;
              background-size: 100%;
            }
          }
          ::v-deep(.swiper-pagination) {
            position: relative;
          }
        }
      }
      .news-lists-box {
        padding-top: 40px;
        .title {
          margin-top: -6px;
          .news-title {
            width: 236px;
            height: 82px;
            background: url(#{$cdnUrl}/assets/images/swiper-two/img_news_news.png)
              no-repeat;
            background-size: 100%;
          }
          .more {
            width: 104px;
            height: 26px;
            background: url(#{$cdnUrl}/assets/images/swiper-two/btn_news_more.png)
              no-repeat;
            background-size: 100%;
            cursor: pointer;
            margin-top: 20px;
          }
        }
        .list-item {
          color: #fff;
          font-size: 20px;
          font-family: 'Arial';
          border-bottom: 2px dashed #69c7ff;
          padding: 20px 0px;
          width: 617px;
          .type {
            font-weight: bold;
            width: 91px;
            height: 30px;
            line-height: 30px;
            // background: url(#{$cdnUrl}/assets/images/swiper-two/btn_news_tab.png)
            //   no-repeat;
            // background-size: 100%;
            font-size: 16px;
            text-align: center;
            color: #be7737;
            margin: 0 15px;
          }
          .text {
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
            display: inline-block;
            cursor: pointer;
            width: 370px;
            margin-right: 10px;
          }
        }
      }
    }
  }
  .slide-world {
    .swiper-world-con {
      .title {
        width: 731px;
        height: 115px;
        background: url(#{$cdnUrl}/assets/images/swiper-three/img_world_world.png)
          no-repeat;
        background-size: 95%;
        margin: 60px auto 25px auto;
      }
      .text {
        width: 1248px;
        height: 178px;
        background: url(#{$cdnUrl}/assets/images/swiper-three/img_world_txt.png)
          no-repeat;
        background-size: 95%;
        margin: 0 auto;
      }
      .world-list {
        li {
          width: 151px;
          height: 321px;
          margin: 40px 25px 0 25px;
          transition: all 0.3s;
          cursor: pointer;
          &.active {
            transform: translateY(-28px);
            .gif-bg {
              animation: tribeInsetShow 0.3s ease forwards;
            }
          }
          &.active::before {
            content: '';
            position: absolute;
            top: -24px;
            left: -11px;
            background: url(#{$cdnUrl}/assets/images/swiper-three/img_world_light.png)
              no-repeat;
            background-size: 95%;
            width: 173px;
            height: 354px;
          }
        }
        .gif-bg {
          position: absolute;
          z-index: 1000;
          bottom: -108px;
          left: 0;
          right: 0;
          margin: 0 auto;
          opacity: 0;
        }
        .gif-bg-defence {
          width: 52px;
          height: 100px;
        }
        .gif-bg-melee {
          width: 148px;
          height: 127px;
        }
        .gif-bg-ranged {
          width: 69px;
          height: 99px;
        }
        .gif-bg-support {
          width: 64px;
          height: 100px;
        }
        @each $i in $world-list-name {
          .#{$i} {
            background: url(#{$cdnUrl}/assets/images/swiper-three/#{$i}.png)
              no-repeat;
            background-size: 95%;
            position: relative;
          }
          .gif-bg-#{$i} {
            background: url(#{$cdnUrl}/assets/images/swiper-three/#{$i}-gif.gif)
              no-repeat
              center
              center;
            background-size: 95%;
          }
        }
      }
    }
  }
  .slide-feature {
    flex-direction: column;
    .swiper-left {
      height: 454px;
    }
    .swiper-feature-con {
      margin-top: 132px;
      height: 500px;
      justify-content: center;
      .news-lists-box {
        position: relative;
        background: none !important;
        .item-title {
          margin: 12px auto 20px auto;
          height: 37px;
        }
        .one-title {
          width: 458px;
          background: url(#{$cdnUrl}/assets/images/swiper-four/img_ts_LandoftheDead.png)
            no-repeat;
          background-size: 100%;
        }
        .two-title {
          width: 533px;
          background: url(#{$cdnUrl}/assets/images/swiper-four/img_ts_UndergroundCavern.png)
            no-repeat;
          background-size: 100%;
        }
        .three-title {
          width: 353px;
          background: url(#{$cdnUrl}/assets/images/swiper-four/img_ts_AncientRuins.png)
            no-repeat;
          background-size: 100%;
        }
        .four-title {
          width: 470px;
          background: url(#{$cdnUrl}/assets/images/swiper-four/img_ts_GreenGrasslands.png)
            no-repeat;
          background-size: 100%;
        }
        .five-title {
          width: 496px;
          background: url(#{$cdnUrl}/assets/images/swiper-four/img_ts_HongKongStreets.png)
            no-repeat;
          background-size: 100%;
        }
        .six-title {
          margin: -4px auto 12px auto !important;
          width: 528px;
          height: 78px;
          background: url(#{$cdnUrl}/assets/images/swiper-four/img_ts_MysteriousUndergroundPalace.png)
            no-repeat;
          background-size: 100%;
        }
        .serven-title {
          width: 421px;
          background: url(#{$cdnUrl}/assets/images/swiper-four/img_ts_InthewildWest.png)
            no-repeat;
          background-size: 100%;
        }
        .item-text {
          padding: 0 25px;
          font-size: 24px;
          font-family: 'Arial';
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 7;
          overflow: hidden;
          text-overflow: ellipsis;
          color: #fff;
          line-height: 50px;
          text-shadow: 1.5px 1.5px 1.5px rgba(0, 0, 0, 0.6);
        }
      }
      .feature-bg {
        div {
          opacity: 1;
          width: 99%;
          height: 99%;
          margin: 0 auto;
        }
        .play-btn {
          width: 113px;
          height: 113px;
          background: url(#{$cdnUrl}/assets/images/swiper-four/btn_main4_bf.png)
            no-repeat;
          background-size: 100%;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          cursor: pointer;
          z-index: 999;
        }
      }
    }
    .swiper-list {
      width: 720px;
      position: relative;
      .feature-swiper {
        width: 645px;
        ::v-deep(.swiper-wrapper) {
          align-items: center;
        }
        ::v-deep(.swiper-slide-active) {
          width: 141px !important;
          height: 82px !important;
          display: flex;
          align-items: center;
          justify-content: center;
          .slide-item {
            cursor: pointer;
            width: 93% !important;
            height: 93% !important;
            margin: 0 auto;
          }
          &::after {
            content: '';
            background: url(#{$cdnUrl}/assets/images/swiper-four/img_ts_light.png)
              no-repeat center center !important;
            background-size: 100% !important;
          }
        }
      }
      :deep(.swiper-slide) {
        width: 116px !important;
        height: 65px;
        cursor: pointer;
        .slide-item {
          width: 100%;
          height: 100%;
        }
        &::after {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background: url(#{$cdnUrl}/assets/images/swiper-four/img_ts_mask.png)
            repeat;
          background-size: 101%;
        }
      }
      .button {
        width: 80px;
        height: 58px;
        position: absolute;
        z-index: 99;
        cursor: pointer;
        transform: translate(0, -50%);
      }
      .button-prev {
        left: -58px;
        top: 50%;
        background: url(#{$cdnUrl}/assets/images/swiper-four/img_ts_next1.png)
          no-repeat;
        background-size: 100%;
      }
      .button-next {
        right: -58px;
        top: 50%;
        background: url(#{$cdnUrl}/assets/images/swiper-four/img_ts_next2.png)
          no-repeat;
        background-size: 100%;
      }
    }
    .feature-bg {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      div {
        width: 100%;
        height: 100%;
        opacity: 0.5;
      }
    }
    .play-btn {
      width: 113px;
      height: 113px;
      background: url(#{$cdnUrl}/assets/images/swiper-four/btn_main4_bf.png)
        no-repeat bottom center;
      background-size: cover;
    }
  }
  // .slide-footer {
  //   height: 200px !important;
  // }
}
</style>
