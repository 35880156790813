<template>
  <div class="nav-pc">
    <div
      class="logo"
      @click="goHome"></div>
    <div class="nav-right">
      <div class="nav-link flex flex-center">
        <div
          v-for="(item, index) in navlist"
          :key="index"
          @click="navChange(item, index)"
          :class="[
            active === item.name
              ? `nav-${item.name}-active`
              : `nav-${item.name}`,
          ]">
          <a></a>
          <span class="active-bg"></span>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref } from 'vue';

defineOptions({
  name: 'TopNav',
});

const navlist = ref([
  { name: 'home' },
  { name: 'news' },
  // { name: 'register' },
  { name: 'world' },
  { name: 'features' },
]);
const emit = defineEmits(['change']);

const props = defineProps({
  active: {
    type: String,
    default: 'home',
  },
});
const goHome = () => {
  push('/home');
};
const navChange = (item, index) => {
  emit('change', item, index);
};
</script>

<style lang="scss" scoped>
// $list-bg-name: 'home', 'register', 'world', 'features';
$list-bg-name: 'home', 'news', 'world', 'features';

.nav-pc {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  width: 100%;
  background: url(#{$cdnUrl}/assets/images/public/img_topban.png) no-repeat;
  background-size: 100%;
  height: 82px;
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  .logo {
    width: 403px;
    height: 138px;
    background: url(#{$cdnUrl}/assets/images/public/img_logo.png) no-repeat;
    background-size: 100%;
    margin: 14px 0 0 36px;
  }
  .nav-link {
    padding-top: 10px;
    margin-left: -30px;
    div {
      position: relative;
      height: 70px;
      width: 125px;
      margin: 0 30px;
    }
    a {
      display: inline-block;
      width: 100%;
      height: 100%;
      cursor: pointer;
      position: absolute;
      z-index: 1;
    }
    .nav-world,
    .nav-world-active {
      width: 152px;
    }
    .nav-features,
    .nav-features-active {
      width: 202px;
    }
    .nav-register,
    .nav-register-active {
      width: 270px;
    }
    @each $i in $list-bg-name {
      .nav-#{$i} a {
        background: url(#{$cdnUrl}/assets/images/public/#{$i}.png) no-repeat;
        background-size: 100%;
      }
      .nav-#{$i}-active {
        a {
          background: url(#{$cdnUrl}/assets/images/public/#{$i}-active.png) no-repeat;
          background-size: 100%;
          transition: all 0.3s;
        }
        .active-bg {
          display: inline-block;
          width: 79px;
          height: 66px;
          position: absolute;
          top: -4px;
          left: 50%;
          background: url(#{$cdnUrl}/assets/images/public/img_tu.png) no-repeat;
          background-size: 100%;
          transform: translate(-50%, 0);
          z-index: 0;
        }
      }
    }
  }
}
</style>
