import { createStore } from 'vuex';

export default createStore({
  state: {
    homeSwiperIndex: 0,
  },
  getters: {},
  mutations: {
    setHomeSwiperIndex: (state, index) => {
      state.homeSwiperIndex = index;
    },
  },
  modules: {},
});
