import { createApp } from 'vue';
import App from './App.vue';
import { setupRouter } from '@/router';
import store from './store';
import '@/assets/css/common.scss';
import '@/assets/js/setRem.js';

// 引入动画
import 'animate.css';

const setupAll = async () => {
  const app = createApp(App);
  app.use(store);
  setupRouter(app);
  app.mount('#app');
};

setupAll();
