<script setup>
import { nextTick, ref } from 'vue';

const menuRef = ref();
const menushow = ref(false);

const showMenu = () => {
  menushow.value = true;
  nextTick(() => {
    menuRef.value.classList.add('run-enter-active');
    setTimeout(() => {
      menuRef.value.classList.remove('run-enter-active');
    }, 500);
  });
};
const hideMenu = () => {
  menuRef.value.classList.add('run-leave-active');
  setTimeout(() => {
    menushow.value = false;
  }, 400);
};
defineOptions({
  name: 'Home',
});

const emit = defineEmits(['change']);

const navlist = ref([
  { name: 'home' },
  { name: 'news' },
  // { name: 'register' },
  { name: 'world' },
  { name: 'features' },
]);

const navChange = (item, index) => {
  hideMenu();
  emit('change', item, index);
};
</script>
<template>
  <div class="m-top-nav">
    <div class="flex">
      <div class="icon"></div>
      <div class="logo"></div>
    </div>
    <div class="flex flex-center">
      <!-- <div class="btn_pre"></div> -->
      <div
        class="btn_list"
        @click="showMenu"></div>
    </div>
    <div
      class="m-menu flex flex-center"
      ref="menuRef"
      v-if="menushow">
      <div
        class="close"
        @click="hideMenu"></div>
      <ul>
        <li
          v-for="(item, index) in navlist"
          :key="index"
          @click="navChange(item, index)"
          :class="`nav-${item.name}`"></li>
      </ul>

      <div class="icon-list flex flex-center">
        <a
          href="https://www.facebook.com/IdlePixelSuperSmash"
          target="_blank"
          class="icon-img-facebook"></a>
        <a
          href="https://discord.gg/XmQfnQhD3W"
          target="_blank"
          class="icon-img-discord"></a>
        <a
          href="https://www.youtube.com/@IdlePixelSuperSmash"
          target="_blank"
          class="icon-img-youtube"></a>
        <a
          href="https://www.taptap.io/user/632061637"
          target="_blank"
          class="icon-img-taptap"></a>
        <a
          href="https://tiktok.com/@idle.pixel.super"
          target="_blank"
          class="icon-img-tiktok"></a>
      </div>
    </div>
  </div>
</template>
<style lang="scss" scoped>
$list-bg-name: 'home', 'news', 'world', 'features';
$icon-list: 'facebook', 'discord', 'youtube', 'taptap', 'tiktok';

.m-top-nav {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100px;
  z-index: 9999;
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  background: url('@/assets/images/mobile/public/topban.png') repeat-x;
  .icon {
    margin: 9px 0 0 20px;
    background: url('@/assets/images/mobile/public/img_icon.png') no-repeat;
    background-size: 100%;
    width: 120px;
    height: 120px;
  }
  .logo {
    background: url('@/assets/images/mobile/public/img_logo.png') no-repeat;
    background-size: 100%;
    width: 193px;
    height: 57px;
    margin: 20px 0 0 15px;
  }
  .btn_pre {
    background: url('@/assets/images/mobile/public/btn_pre.png') no-repeat;
    background-size: 100%;
    width: 230px;
    height: 62px;
    margin-right: 15px;
  }
  .btn_list {
    background: url('@/assets/images/mobile/public/btn_list.png') no-repeat;
    background-size: 100%;
    width: 60px;
    height: 47px;
    margin-right: 20px;
  }
  .m-menu {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background: rgba(0, 0, 0, 0.8);
    .close {
      width: 46px;
      height: 46px;
      background: url('@/assets/images/mobile/menu/close.png') no-repeat;
      background-size: 100%;
      position: absolute;
      top: 28px;
      right: 30px;
    }
    li {
      height: 45px;
      margin: 120px auto;
    }
    @each $i in $list-bg-name {
      .nav-#{$i} {
        background: url('@/assets/images/mobile/menu/#{$i}.png') no-repeat;
        background-size: 100%;
      }
    }
    .nav-home {
      width: 158px;
    }
    .nav-news {
      width: 156px;
    }
    .nav-world {
      width: 198px;
    }
    .nav-register {
      width: 398px;
    }
    .nav-features {
      width: 286px;
    }
    .icon-list {
      position: absolute;
      bottom: 80px;
      left: 50%;
      transform: translate(-50%, 0);
      a {
        display: inline-block;
        width: 100px;
        height: 100px;
        margin: 0 16px;
        cursor: pointer;
      }
      @each $i in $icon-list {
        .icon-img-#{$i} {
          background: url(#{$cdnUrl}/assets/images/footer/#{$i}.png) no-repeat;
          background-size: 100%;
        }
      }
    }
  }
}
</style>
