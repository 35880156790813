<template>
  <div class="footer flex flex-center animate__animated animate__fadeInUp">
    <div class="k2-logo"></div>
    <div class="game-logo"></div>

    <div class="footer-icons">
      <div class="icon-list flex flex-center">
        <a
          href="https://www.facebook.com/IdlePixelSuperSmash"
          target="_blank"
          class="icon-img-facebook"></a>
        <a
          href="https://discord.gg/XmQfnQhD3W"
          target="_blank"
          class="icon-img-discord"></a>
        <a
          href="https://www.youtube.com/@IdlePixelSuperSmash"
          target="_blank"
          class="icon-img-youtube"></a>
        <a
          href="https://www.taptap.io/user/632061637"
          target="_blank"
          class="icon-img-taptap"></a>
        <a
          href="https://tiktok.com/@idle.pixel.super"
          target="_blank"
          class="icon-img-tiktok"></a>
      </div>
      <div class="text flex flex-center">
        <span
          ><router-link
            to="/terms"
            target="_blank"
            >TERMS OF SERVICE</router-link
          ></span
        >
        <span class="line"></span>
        <span
          ><router-link
            to="/privacy"
            target="_blank"
            >PRIVACY POLICY</router-link
          >
        </span>
      </div>
    </div>
  </div>
</template>
<style lang="scss" scoped>
$icon-list: 'facebook', 'discord', 'youtube', 'taptap', 'tiktok';

.footer {
  width: 100%;
  height: 120px;
  background: url(#{$cdnUrl}/assets/images/footer/bg_end_bg.png) repeat;
  background-size: 100%;
  z-index: 999;
  position: fixed;
  bottom: 0;
  left: 0;
  .icon-list {
    a {
      display: inline-block;
      width: 50px;
      height: 50px;
      margin: 0 8px;
      cursor: pointer;
    }
    @each $i in $icon-list {
      .icon-img-#{$i} {
        background: url(#{$cdnUrl}/assets/images/footer/#{$i}.png) no-repeat;
        background-size: 100%;
      }
    }
  }
  .k2-logo {
    width: 248px;
    height: 84px;
    background: url(#{$cdnUrl}/assets/images/footer/img_end_k2logo.png)
      no-repeat;
    background-size: 100%;
  }
  .game-logo {
    width: 248px;
    height: 84px;
    background: url(#{$cdnUrl}/assets/images/footer/img_end_gamelogo.png)
      no-repeat;
    background-size: 100%;
    margin: 0 80px 0 36px;
  }
  .footer-icons {
    color: #fff;
    font-size: 14px;

    .line {
      display: inline-block;
      height: 14px;
      width: 1px;
      background-color: #fff;
      margin: 0 25px;
    }
    .text {
      a {
        color: #fff;
      }

      margin-top: 14px;
    }
  }
}
</style>
