import axios from 'axios';

const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API,
  timeout: 6000,
});

service.interceptors.response.use((response) => {
  let result = response.data;
  return result;
});
export default service;
