import { createRouter, createWebHashHistory } from 'vue-router';
import { isMobile } from '@/assets/js/utils';
import { setupGuard } from './nprogress';
import HomeView from '../views/Home.vue';
import MHomeView from '../views/mobile/Home.vue';

let routes = [];
if (!isMobile()) {
  routes = [
    {
      path: '/',
      name: 'home',
      component: HomeView,
    },
    {
      path: '/privacy',
      name: 'privacy',
      component: () =>
        import(/* webpackChunkName: "privacy" */ '../views/Privacy.vue'),
    },
    {
      path: '/terms',
      name: 'terms',
      component: () =>
        import(/* webpackChunkName: "terms" */ '../views/Terms.vue'),
    },
    {
      path: '/newslists',
      name: 'newslists',
      component: () =>
        import(/* webpackChunkName: "newslist" */ '../views/NewsLists.vue'),
    },
    {
      path: '/newsdetail',
      name: 'newsdetail',
      component: () =>
        import(/* webpackChunkName: "newsdetail" */ '../views/NewsDetail.vue'),
    },
  ];
} else {
  routes = [
    {
      path: '/',
      name: 'home',
      component: MHomeView,
    },
    {
      path: '/privacy',
      name: 'privacy',
      component: () =>
        import(/* webpackChunkName: "privacy" */ '../views/Privacy.vue'),
    },
    {
      path: '/terms',
      name: 'terms',
      component: () =>
        import(/* webpackChunkName: "terms" */ '../views/Terms.vue'),
    },
    {
      path: '/newslists',
      name: 'newslists',
      component: () =>
        import(/* webpackChunkName: "newslist" */ '../views/NewsLists.vue'),
    },
    {
      path: '/newsdetail',
      name: 'newsdetail',
      component: () =>
        import(/* webpackChunkName: "newsdetail" */ '../views/NewsDetail.vue'),
    },
  ];
}

const router = createRouter({
  history: createWebHashHistory(),
  routes,
  scrollBehavior() {
    return { left: 0, top: 0 };
  },
});

export function setupRouter(app) {
  setupGuard(router);
  app.use(router);
  return router;
}

export default router;
