<script setup>
import { ref, onMounted, nextTick, computed } from 'vue';
import { useRouter, useRoute } from 'vue-router';
import * as spine from '@esotericsoftware/spine-player';
import { Mousewheel, Pagination, Autoplay } from 'swiper';
import MTopNav from '@/components/m-top-nav/index.vue';
import { Swiper, SwiperSlide } from 'swiper/vue';
import { typeTranslate } from '@/assets/js/utils';
import { getArticleLists, getBannerLists } from '@/api/index';
import YoutubePlayer from '@/components/youtubePlayer/index.vue';
import MFooter from '@/components/m-footer/index.vue';
import { useStore } from 'vuex';
import 'swiper/css';
import 'swiper/css/pagination';
import '@esotericsoftware/spine-player/dist/spine-player.css';

defineOptions({
  name: 'Home',
});

const store = useStore();
const modules = [Mousewheel];
const homeActiveIndex = ref(0);
const homeSwiperRef = ref(null);
const newsBanner = ref([]);
const setHomeSwiperRef = (swiper) => {
  homeSwiperRef.value = swiper;
};

const slideChangeTransitionStart = () => {
  homeActiveIndex.value = homeSwiperRef.value.activeIndex;
  if (homeActiveIndex.value === 3) {
    loadFeatureSwiper();
  }
};

const newsModules = [Pagination, Autoplay];
const newslist = ref([]);
const newsBulletRenderer = (index, className) => {
  const _className = `${className} swiper-pagination-bullet-custom`;
  return `<span class="${_className}"></span>`;
};

const router = useRouter();
const handelMore = () => {
  router.push('/newslists');
};

const handelNewInfo = (item) => {
  router.push({
    path: '/newsdetail',
    query: {
      id: item.id,
    },
  });
};
const getArticleListData = () => {
  getArticleLists({
    game_id: 2,
    type: 0,
    page: 1,
    limit: 6,
  }).then((res) => {
    newslist.value = res.data.list.map((v) => ({
      published_at: v.published_at.slice(0, 10),
      name: v.name,
      id: v.id,
      flag: v.flag,
      type: v.type,
    }));
  });
};

const getBanner = () => {
  getBannerLists({
    game_id: 2,
    page: 1,
    limit: 5,
  }).then((res) => {
    newsBanner.value = res.data.list;
  });
};

const worldList = ref([
  {
    name: 'defence',
  },
  {
    name: 'melee',
  },
  {
    name: 'ranged',
  },
  {
    name: 'support',
  },
]);
const worldActive = ref('');
const mouseOverHandler = (item) => {
  worldActive.value = item.name;
};

const videoShow = ref(false);
const swiperIndex = ref(0);
const featureModules = [Autoplay];
const featureList = ref([
  // {
  //   name: 'one',
  //   text: "Tucked between mountains, this village boasts a rich history and simple customs. Generations of villagers have lived here in tranquility and harmony. Secluded from the hustle and bustle, time seems to flow more gently, creating a haven reminiscent of a mythical utopia. Ancient trees reach for the skies, while babbling brooks narrate the village's age-old legends, safeguarding the peaceful haven of its inhabitants.",
  // },
  {
    name: 'one',
    text: 'Located in the barren wilds, legend has it that this place was once a battlefield, where blood flowed like rivers, hence its ominous name. As time passed, it remains desolate and eerie, with howling cold winds that deter any soul from nearing. Rumors tell of strange noises heard at night, chilling to the core, making it a famously forbidden zone far and wide.',
    id: '3bN37tMK5zY',
  },
  {
    name: 'two',
    text: "Hidden deep within the mountain's belly, mysterious and winding. The cave walls are lined with bizarre rocks and stalactites hang from above, casting mottled light and shadows. Inside, the cave alternates between utter silence and the gentle sound of dripping water, as if whispering the secrets of the ages, drawing countless adventurers to explore its depths.",
    id: 'EHbAgtKqf-o',
  },
  {
    name: 'three',
    text: 'Standing tall on the vast land, weathered by storms yet still majestic. Ancient totems carved on the stone walls speak of former glory. The ruins hide countless secrets, drawing explorers from all over to discover them. Each stone seems to tell a thousand-year-old legend.',
    id: 'vN5gvbdbHp4',
  },
  {
    name: 'four',
    text: 'Vast and boundless, the land is covered in lush green grass, with herds of cattle and sheep grazing. The gentle breeze stirs the grass, creating rolling waves, while the air is filled with the scent of flowers. This is a natural paradise, an oasis of life. Here, the herders graze their livestock, living in harmony with nature, together weaving the legend of the grasslands',
    id: 'J8Jb-gt3MEE',
  },
  {
    name: 'five',
    text: 'Bustling and lively, neon lights flicker. Skyscrapers stand tall, streets are filled with the hustle and bustle, and crowds move like a tide. This place is a shopping paradise, a haven for gourmet food. Every corner of the streets radiates the vitality and charm of a modern city, drawing countless visitors to experience the prosperity and diversity of this urban landscape.',
    id: 'qFh418HBi6U',
  },
  {
    name: 'six',
    text: "Hidden deep beneath the earth, abundant treasures lie alongside lurking dangers. The palace's corridors twist and turn, light and shadows intertwine, as if leading to another mysterious world. Legend has it that only those who possess both wisdom and courage can unravel the secrets of this underground palace, obtaining endless wealth and glory.",
    id: 'PKSc70p5DIo',
  },
  {
    name: 'serven',
    text: 'In the wild West, brave cowboys ride across the vast prairies, engaging in life-and-death struggles with outlaws. Under the scorching sun, iron steeds gallop, amidst a hail of bullets, legendary tales abound. In this land filled with challenges and opportunities, only the courageous can script their own chapters of glory.',
    id: 'v_P5TyF1M5A',
  },
]);
const featureSwiperRef = ref(null);
const videoId = ref('');
const setFeatureSwiperRef = (swiper) => {
  featureSwiperRef.value = swiper;
};
const updateSwiperIndex = () => {
  if (featureSwiperRef.value) {
    swiperIndex.value = featureSwiperRef.value.realIndex;
    featureSwiperRef.value.autoplay.start();
  }
};

const loadFeatureSwiper = () => {
  setFeatureSwiperRef();
  videoShow.value = false;
  swiperIndex.value = 0;
};

const prevSwiperSlide = () => {
  videoShow.value = false;
  if (featureSwiperRef.value) {
    featureSwiperRef.value.autoplay.start();
    featureSwiperRef.value.slidePrev();
  }
};
const nextSwiperSlide = () => {
  videoShow.value = false;
  if (featureSwiperRef.value) {
    featureSwiperRef.value.autoplay.start();
    featureSwiperRef.value.slideNext();
  }
};

const handelItem = (item, index) => {
  videoShow.value = false;
  if (featureSwiperRef.value) {
    featureSwiperRef.value.autoplay.start();
    featureSwiperRef.value.slideTo(index + 5, 2000, false);
  }
};

const playBgVideo = () => {
  videoShow.value = true;
  nextTick(() => {
    featureSwiperRef.value.autoplay.stop();
    videoId.value = featureList.value[swiperIndex.value].id;
  });
};

const footerShow = ref(false);

const scrollView = (_item, index) => {
  footerShow.value = false;
  homeActiveIndex.value = index;
  homeSwiperRef.value.slideTo(index, 1000, false);
  if (index === 3) {
    loadFeatureSwiper();
  }
};

const scrollFunc = () => {
  const slideFeature = document.getElementById('slide-feature');
  if (slideFeature.addEventListener) {
    var touchStartY = 0;
    slideFeature.addEventListener('touchstart', function (event) {
      touchStartY = event.touches[0].clientY;
    });
    slideFeature.addEventListener('touchmove', function (event) {
      var touchMoveY = event.touches[0].clientY;
      if (touchMoveY > touchStartY) {
        console.log('向下滚动');
        footerShow.value = false;
      } else {
        console.log('向上滚动');
        footerShow.value = true;
      }
    });
  }
};
onMounted(() => {
  homeActiveIndex.value = store.state.homeSwiperIndex;
  homeSwiperRef.value.slideTo(homeActiveIndex.value, 0, false);
  getArticleListData();
  getBanner();
  scrollFunc();
});
</script>
<template>
  <div class="m-home">
    <MTopNav @change="scrollView" />
    <Swiper
      style="height: 100%"
      class="home-swiper"
      :modules="modules"
      direction="vertical"
      :mousewheel="true"
      :threshold-time="5000"
      @swiper="setHomeSwiperRef"
      @slide-change-transition-start="slideChangeTransitionStart">
      <SwiperSlide class="w-h-full swiper-one">
        <div class="w-h-full swiper-one-bg"></div>
        <div class="swiper-moon"></div>
        <div class="swiper-bottom"></div>
        <div class="swiper-hero"></div>
        <div class="swiper-title"></div>
        <div class="swiper-btns flex">
          <a
            href="https://play.google.com/store/apps/details?id=cc.k2games.ipss"
            class="swiper-btn-google"></a>
          <a
            href="https://www.taptap.io/user/632061637"
            class="swiper-btn-taptap"></a>
        </div>
        <div class="swiper-btn-scroll"></div>
      </SwiperSlide>
      <SwiperSlide class="w-h-full swiper-two flex flex-center flex-column">
        <div
          class="animate__animated animate__fadeInLeft swiper-main"
          v-if="homeActiveIndex === 1">
          <div class="swiper-bg swiper-bg-one"></div>
          <Swiper
            :modules="newsModules"
            :pagination="{
              clickable: true,
              renderBullet: newsBulletRenderer,
            }"
            :autoplay="{
              delay: 2000,
              disableOnInteraction: false,
            }">
            <SwiperSlide
              v-for="(item, index) in newsBanner"
              :key="index">
              <img
                :src="item.pic"
                style="width: 100%"
            /></SwiperSlide>
          </Swiper>
        </div>
        <div
          class="news-lists-box animate__animated animate__fadeInUp"
          v-if="homeActiveIndex === 1">
          <div class="title flex flex-between">
            <div class="news-title"></div>
            <div
              class="more"
              @click="handelMore"></div>
          </div>
          <div
            class="list-item flex"
            v-for="(item, index) in newslist"
            :key="index"
            @click="handelNewInfo(item)">
            <label
              :class="['type', `type_${typeTranslate(item.type)}`]"></label>
            <span class="text">{{ item.name }}</span>
            <span>{{ item.published_at }}</span>
          </div>
        </div>
        <div class="mask-bg"></div>
      </SwiperSlide>
      <SwiperSlide class="slide-world flex flex-center flex-column">
        <div class="mask-bg"></div>
        <div
          class="swiper-world-con flex flex-center flex-column"
          v-if="homeActiveIndex === 2">
          <div class="title animate__animated animate__fadeInUp"></div>
          <div
            class="text animate__animated animate__fadeInUp animate__delay-0.15s"></div>
          <ul
            class="world-list flex flex-center animate__animated animate__fadeInUp animate__delay-0.15s">
            <li
              v-for="(item, index) in worldList"
              :class="[item.name, worldActive === item.name ? 'active' : '']"
              :key="index"
              @mouseover="mouseOverHandler(item)">
              <div :class="['gif-bg', `gif-bg-${item.name}`]"></div>
            </li>
          </ul>
        </div>
      </SwiperSlide>
      <SwiperSlide
        class="slide-feature flex flex-center flex-column"
        id="slide-feature">
        <div class="feature-bg">
          <div :class="`feature-${featureList[swiperIndex].name}`"></div>
        </div>

        <div class="mask-bg"></div>
        <div
          class="swiper-feature-con"
          v-if="homeActiveIndex === 3">
          <div class="swiper-main animate__animated animate__fadeInLeft">
            <div class="swiper-bg swiper-bg-two"></div>

            <YoutubePlayer
              v-if="videoShow"
              :videoId="videoId"></YoutubePlayer>
            <div
              class="feature-bg"
              v-else>
              <div :class="`feature-bg-${featureList[swiperIndex].name}`"></div>
              <div
                class="play-btn"
                @click="playBgVideo"></div>
            </div>
          </div>
          <div
            class="news-lists-box animate__animated animate__fadeInUp animate__delay-0.1s">
            <div
              v-for="(items, indexs) in featureList"
              :key="indexs">
              <template v-if="indexs === swiperIndex">
                <div class="title">
                  <div :class="['item-title', `${items.name}-title`]"></div>
                </div>
                <div class="item-text">{{ items.text }}</div>
              </template>
            </div>
          </div>
        </div>
        <div
          class="swiper-list animate__animated animate__fadeInUp animate__delay-0.3s"
          v-if="homeActiveIndex === 3">
          <swiper
            class="feature-swiper"
            :modules="featureModules"
            :autoplay="{
              delay: 900000,
              disableOnInteraction: false,
            }"
            :slides-per-view="5"
            :space-between="9"
            :loop="true"
            :keyboard="true"
            :centered-slides="true"
            @swiper="setFeatureSwiperRef"
            @slide-change="updateSwiperIndex">
            <swiper-slide
              v-for="(item, index) in featureList"
              :key="index"
              @click="handelItem(item, index)">
              <div :class="['slide-item', `feature-bg-${item.name}`]"></div>
            </swiper-slide>
          </swiper>

          <div
            class="button-prev button"
            @click="prevSwiperSlide"></div>

          <div
            class="button-next button"
            @click="nextSwiperSlide"></div>
        </div>
      </SwiperSlide>
    </Swiper>
    <MFooter v-if="footerShow"></MFooter>
    <div class="right-menu-box"></div>
  </div>
</template>
<style lang="scss" scoped>
$world-list-name: 'defence', 'melee', 'ranged', 'support';

.m-home {
  width: 100vw;
  height: 100vh;
  .w-h-full {
    width: 100%;
    height: 100vh;
  }
  .swiper-one {
    background: url('~@/assets/images/mobile/main1/img_sy_di.png') repeat;
    background-size: cover;
    position: relative;
    .swiper-one-bg {
      background: url('~@/assets/images/mobile/main1/img_sy_vista.png')
        no-repeat center bottom;
      background-size: cover;
      position: absolute;
      left: 0;
      top: 0;
    }
    .swiper-bottom {
      width: 100%;
      height: 285px;
      background: url('~@/assets/images/mobile/main1/img_sy_outlook.png')
        no-repeat center bottom;
      background-size: cover;
      position: absolute;
      bottom: 0;
      left: 0;
    }
    .swiper-hero {
      width: 722px;
      height: 722px;
      background: url('~@/assets/images/mobile/main1/img_sy_hero.png') no-repeat
        center center;
      background-size: 100%;
      position: absolute;
      bottom: 260px;
      left: 50%;
      transform: translate(-50%, 0%);
      z-index: 2;
    }
    .swiper-moon {
      position: absolute;
      background: url('~@/assets/images/mobile/main1/img_sy_moon.png') no-repeat
        center center;
      background-size: 100%;
      width: 126px;
      height: 125px;
      top: 360px;
      left: 50%;
      transform: translate(-50%, 0%);
      z-index: 1;
    }
    .swiper-title {
      position: absolute;
      background: url('~@/assets/images/mobile/main1/img_sy_wenan.png')
        no-repeat center center;
      background-size: 100%;
      width: 633px;
      height: 153px;
      top: 205px;
      left: 50%;
      transform: translate(-50%, 0%);
    }
    .swiper-btns {
      position: absolute;
      left: 50%;
      transform: translate(-50%, 0%);
      bottom: 180px;
      a {
        width: 320px;
        height: 100px;
        margin: 0 17px;
        display: block;
      }
      .swiper-btn-google {
        background: url('@/assets/images/mobile/main1/btn_sy_googlegoogle.png')
          no-repeat;
        background-size: 100%;
      }
      .swiper-btn-taptap {
        background: url('@/assets/images/mobile/main1/btn_sy_tap.png') no-repeat;
        background-size: 100%;
      }
    }
    .swiper-btn-scroll {
      width: 68px;
      height: 42px;
      position: absolute;
      left: 50%;
      transform: translate(-50%, 0%);
      bottom: 50px;
      background: url('@/assets/images/mobile/main1/btn_sy_scroll.png')
        no-repeat;
      background-size: 100%;
      animation: fade-up-in 0.6s ease-in-out infinite alternate;
    }
  }
  .swiper-bg {
    width: 672px;
    height: 378px;
    position: absolute;
    z-index: 99;
  }
  .swiper-two {
    background: url('~@/assets/images/mobile/main2/bg_news_bg.png') no-repeat
      top center;
    background-size: cover;
    .swiper-main {
      width: 672px;
      height: 445px;
      position: relative;
      overflow: hidden;
    }
    .swiper-bg-one {
      background: url(@/assets/images/mobile/main2/img_news_wk.png) no-repeat;
      background-size: 100%;
    }
    .swiper {
      position: absolute;
      overflow: hidden;
      width: 99%;
      height: 99%;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      ::v-deep(.swiper-wrapper) {
        height: 90%;
      }
    }
    .swiper-slide {
      img {
        width: 672px;
        height: 376px;
      }
    }
    ::v-deep(.swiper-pagination-bullet-custom) {
      width: 35px !important;
      height: 34px !important;
      margin: 0 2px;
      background: url(#{$cdnUrl}/assets/images/swiper-two/btn_news_lb0.png)
        no-repeat;
      background-size: 100%;
      &.swiper-pagination-bullet-active {
        background: url(#{$cdnUrl}/assets/images/swiper-two/btn_news_lb.png)
          no-repeat;
        background-size: 100%;
      }
    }
    ::v-deep(.swiper-pagination) {
      position: relative;
    }
    .news-lists-box {
      width: 713px;
      height: 504px;
      background: url(#{$cdnUrl}/assets/images/swiper-two/img_maskan.png)
        no-repeat;
      background-size: 100%;
      .title {
        .news-title {
          width: 236px;
          height: 82px;
          background: url(#{$cdnUrl}/assets/images/swiper-two/img_news_news.png)
            no-repeat;
          background-size: 100%;
        }
        .more {
          width: 104px;
          height: 26px;
          background: url(#{$cdnUrl}/assets/images/swiper-two/btn_news_more.png)
            no-repeat;
          background-size: 100%;
          cursor: pointer;
          margin-top: 20px;
        }
      }
      .title {
        width: 673px;
        background: url(#{$cdnUrl}/assets/images/swiper-two/img_news_line.png)
          no-repeat bottom center;
        background-size: 100%;
        padding: 0 20px 25px 20px;
        margin: -6px auto 0 auto;
      }
      .list-item {
        color: #fff;
        font-size: 20px;
        font-family: 'Arial';
        border-bottom: 2px dashed #69c7ff;
        padding: 15px 0px;
        width: 632px;
        margin: 0 auto;
        display: flex;
        flex-direction: row;
        align-items: center;
        .type {
          font-weight: bold;
          width: 112px;
          height: 52px;
          font-size: 16px;
          text-align: center;
          color: #be7737;
          margin: 0 15px;
        }
        .text {
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
          display: inline-block;
          cursor: pointer;
          width: 350px;
          margin-right: 10px;
          // line-height: 62px;
        }
      }
    }
  }

  .mask-bg {
    width: 673px;
    height: 13px;
    position: absolute;
    bottom: 34px;
    left: 50%;
    z-index: 999;
    transform: translate(-50%, 0);
    background: url('~@/assets/images/mobile/public/img_ts_masktxt.png')
      no-repeat;
    background-size: 100%;
  }
  .slide-world {
    background: url(~@/assets/images/mobile/main3/bg.png) no-repeat no-repeat
      center center;
    background-size: cover;
    .swiper-world-con {
      .title {
        width: 672px;
        height: 115px;
        background: url(#{$cdnUrl}/assets/images/swiper-three/img_world_world.png)
          no-repeat;
        background-size: 100%;
        margin: 0px auto 40px auto;
      }
      .text {
        width: 638px;
        height: 301px;
        background: url(~@/assets/images/mobile/main3/txt.png) no-repeat;
        background-size: 100%;
        margin: 0 auto;
      }
      .world-list {
        width: 672px;
        margin: 0 auto;
        li {
          width: 151px;
          height: 321px;
          margin: 80px 12px 0 12px;
          transition: all 0.3s;
          cursor: pointer;
          &.active {
            transform: translateY(-28px);
            .gif-bg {
              animation: tribeInsetShow 0.3s ease forwards;
            }
          }
          &.active::before {
            content: '';
            position: absolute;
            top: -24px;
            left: -11px;
            background: url(#{$cdnUrl}/assets/images/swiper-three/img_world_light.png)
              no-repeat;
            background-size: 95%;
            width: 173px;
            height: 354px;
          }
        }
        .gif-bg {
          position: absolute;
          z-index: 1000;
          bottom: -108px;
          left: 0;
          right: 0;
          margin: 0 auto;
          opacity: 0;
        }
        .gif-bg-defence {
          width: 52px;
          height: 100px;
        }
        .gif-bg-melee {
          width: 148px;
          height: 127px;
        }
        .gif-bg-ranged {
          width: 69px;
          height: 99px;
        }
        .gif-bg-support {
          width: 64px;
          height: 100px;
        }
        @each $i in $world-list-name {
          .#{$i} {
            background: url(#{$cdnUrl}/assets/images/swiper-three/#{$i}.png)
              no-repeat;
            background-size: 100%;
            position: relative;
          }
          .gif-bg-#{$i} {
            background: url(#{$cdnUrl}/assets/images/swiper-three/#{$i}-gif.gif)
              no-repeat
              center
              center;
            background-size: 100%;
          }
        }
      }
    }
  }
  .slide-feature {
    background: url(#{$cdnUrl}/assets/images/swiper-four/img_ts_bg_ts_bg.png)
      repeat;
    background-size: cover;
    .swiper-main {
      width: 672px;
      height: 378px;
      position: relative;
      overflow: hidden;
      margin: 0 auto;
    }
    .swiper-bg-two {
      background: url(#{$cdnUrl}/assets/images/swiper-four/img_ts_wk.png)
        no-repeat;
      background-size: 100%;
      pointer-events: none;
    }
    .swiper-feature-con {
      width: 672px;
      .news-lists-box {
        margin: 72px 0;
        position: relative;
        background: none !important;
        .title {
          width: 673px;
          background: url(#{$cdnUrl}/assets/images/swiper-two/img_news_line.png)
            no-repeat bottom center;
          background-size: 100%;
          padding: 0 20px 15px 20px;
        }
        .item-title {
          margin: 12px auto 20px auto;
          height: 37px;
        }
        .one-title {
          width: 458px;
          background: url(#{$cdnUrl}/assets/images/swiper-four/img_ts_LandoftheDead.png)
            no-repeat;
          background-size: 100%;
        }
        .two-title {
          width: 533px;
          background: url(#{$cdnUrl}/assets/images/swiper-four/img_ts_UndergroundCavern.png)
            no-repeat;
          background-size: 100%;
        }
        .three-title {
          width: 353px;
          background: url(#{$cdnUrl}/assets/images/swiper-four/img_ts_AncientRuins.png)
            no-repeat;
          background-size: 100%;
        }
        .four-title {
          width: 470px;
          background: url(#{$cdnUrl}/assets/images/swiper-four/img_ts_GreenGrasslands.png)
            no-repeat;
          background-size: 100%;
        }
        .five-title {
          width: 496px;
          background: url(#{$cdnUrl}/assets/images/swiper-four/img_ts_HongKongStreets.png)
            no-repeat;
          background-size: 100%;
        }
        .six-title {
          margin: -4px auto 12px auto !important;
          width: 528px;
          height: 78px;
          background: url(#{$cdnUrl}/assets/images/swiper-four/img_ts_MysteriousUndergroundPalace.png)
            no-repeat;
          background-size: 100%;
        }
        .serven-title {
          width: 421px;
          background: url(#{$cdnUrl}/assets/images/swiper-four/img_ts_InthewildWest.png)
            no-repeat;
          background-size: 100%;
        }
        .item-text {
          padding: 0 25px;
          font-size: 24px;
          font-family: 'Arial';
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 7;
          overflow: hidden;
          text-overflow: ellipsis;
          color: #fff;
          line-height: 50px;
          text-shadow: 1.5px 1.5px 1.5px rgba(0, 0, 0, 0.6);
        }
      }
      .feature-bg {
        div {
          opacity: 1;
          width: 99%;
          height: 99%;
          margin: 0 auto;
        }
        .play-btn {
          width: 113px;
          height: 113px;
          background: url(#{$cdnUrl}/assets/images/swiper-four/btn_main4_bf.png)
            no-repeat;
          background-size: 100%;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          cursor: pointer;
          z-index: 999;
        }
      }
    }
    .swiper-list {
      width: 600px;
      position: relative;
      .feature-swiper {
        width: 568px;
        ::v-deep(.swiper-wrapper) {
          align-items: center;
        }
        ::v-deep(.swiper-slide-active) {
          width: 141px !important;
          height: 82px !important;
          display: flex;
          align-items: center;
          justify-content: center;
          .slide-item {
            cursor: pointer;
            width: 93% !important;
            height: 93% !important;
            margin: 0 auto;
          }
          &::after {
            content: '';
            background: url(~@/assets/images/mobile/main4/img_ts_light.png)
              no-repeat center center !important;
            background-size: 100% !important;
          }
        }
      }
      :deep(.swiper-slide) {
        width: 96px !important;
        height: 54px;
        cursor: pointer;
        .slide-item {
          width: 100%;
          height: 100%;
        }
        &::after {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background: url(~@/assets/images/mobile/main4/img_ts_mask.png) repeat;
          background-size: 100%;
        }
      }
      .button {
        width: 66px;
        height: 52px;
        position: absolute;
        z-index: 99;
        cursor: pointer;
        transform: translate(0, -50%);
      }
      .button-prev {
        left: -58px;
        top: 50%;
        background: url(#{$cdnUrl}/assets/images/swiper-four/img_ts_next1.png)
          no-repeat;
        background-size: 100%;
      }
      .button-next {
        right: -58px;
        top: 50%;
        background: url(#{$cdnUrl}/assets/images/swiper-four/img_ts_next2.png)
          no-repeat;
        background-size: 100%;
      }
    }
    .feature-bg {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      div {
        width: 100%;
        height: 100%;
        opacity: 0.5;
      }
    }
    .feature-one {
      background: url('~@/assets/images/mobile/main4/BG_Customs_1.jpg')
        no-repeat center center;
      background-size: cover;
    }
    .feature-two {
      background: url('~@/assets/images/mobile/main4/BG_Customs_2.jpg')
        no-repeat center center;
      background-size: cover;
    }
    .feature-three {
      background: url('~@/assets/images/mobile/main4/BG_Customs_3.jpg')
        no-repeat center center;
      background-size: cover;
    }
    .feature-four {
      background: url('~@/assets/images/mobile/main4/BG_Customs_4.jpg')
        no-repeat center center;
      background-size: cover;
    }
    .feature-five {
      background: url('~@/assets/images/mobile/main4/BG_Customs_5.jpg')
        no-repeat center center;
      background-size: cover;
    }
    .feature-six {
      background: url('~@/assets/images/mobile/main4/BG_Customs_6.jpg')
        no-repeat center center;
      background-size: cover;
    }
    .feature-serven {
      background: url('~@/assets/images/mobile/main4/BG_Customs_7.jpg')
        no-repeat center center;
      background-size: cover;
    }
    .play-btn {
      width: 113px;
      height: 113px;
      background: url(#{$cdnUrl}/assets/images/swiper-four/btn_main4_bf.png)
        no-repeat bottom center;
      background-size: cover;
    }
  }
}
</style>
