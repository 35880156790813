import request from '@/assets/js/request';

// 获取轮博图
export function getBannerLists(params) {
  return request({
    url: '/website/ads/list',
    method: 'post',
    data: params,
  });
}
// 获取文章列表
export function getArticleLists(params) {
  return request({
    url: '/website/article/list',
    method: 'post',
    data: params,
  });
}
// 获取文章详情
export function getArticleInfo(params) {
  return request({
    url: '/website/article/info',
    method: 'post',
    data: params,
  });
}

// 点击记录
export function getBtnlog(params) {
  return request({
    url: '/website/event/add',
    method: 'post',
    data: params,
  });
}
