<template>
  <div class="video-palyer">
    <iframe
      id="youtubePlayer"
      width="100%"
      height="100%"
      :src="youtubeUrl"
      title="YouTube video player"
      frameborder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture;"
      referrerpolicy="strict-origin-when-cross-origin"
      allowfullscreen></iframe>
  </div>
</template>

<script>
export default {
  name: 'youtubePlayer',
  props: {
    videoId: {
      type: String,
      required: true,
    },
  },
  computed: {
    youtubeUrl() {
      return `https://www.youtube.com/embed/${this.videoId}?autoplay=1&playsinline=1&controls=0&showinfo=1&enablejsapi=1&rel=0`;
    },
  },
};
</script>

<style lang="scss" scoped>
.video-palyer {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  height: 100%;
  overflow: hidden;
  z-index: 98;
  transform: translate(-50%, -50%);
  background: #000;
}
</style>
