<template>
  <div class="video-palyer">
    <video
      id="videoPlayer"
      class="video-js vjs-default-skin vjs-big-play-centered"
      playsinline
      webkit-playsinline
      muted
      loop
      preload="auto"
      autoplay="autoplay"
      controlsList="nodownload"
      oncontextmenu="return false">
      您的浏览器不支持 HTML5 video 标签。
    </video>
  </div>
</template>

<script setup>
import { ref, onUnmounted } from 'vue';
import videoJs from 'video.js';
import 'video.js/dist/video-js.css';

defineOptions({
  name: 'VideoPlayer',
});
defineExpose({
  playVideo,
});

const videoPlayer = ref();

function playVideo(name) {
  videoPlayer.value = videoJs('videoPlayer');
  videoPlayer.value.src([
    {
      type: 'video/mp4',
      src: `https://cdn.k2games.cc/idlepixel/assets/images/${name}.mp4`,
    },
  ]);
  videoPlayer.value.play();
}

const destroyVideo = () => {
  if (videoPlayer.value) {
    videoPlayer.value.dispose();
  }
};

onUnmounted(() => {
  destroyVideo();
});
</script>

<style lang="scss" scoped>
.video-palyer {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  height: 100%;
  overflow: hidden;
  z-index: 98;
  transform: translate(-50%, -50%);
}
.video-js {
  width: 100%;
  height: 100%;
}
.vjs-tech {
  object-fit: cover;
  width: 100%;
}
</style>
