export function isExternal(path) {
  return /^http(s)?:\/\/.+/.test(path);
}

export function isMobile() {
  let flag = navigator.userAgent.match(
    /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
  );
  return flag;
}

export const typeTranslate = (type) => {
  const typeMap = {
    0: 'latest',
    1: 'news',
    2: 'activity',
    3: 'notice',
  };
  return typeMap[type];
};
